import React, { Component } from "react";
import fetch from "isomorphic-fetch";
import {
  Button,
  Card,
  Checkbox,
  FooterHelp,
  FormLayout,
  Layout,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
} from "@shopify/polaris";

import ContactModal from "../../components/contact-modal";
import Unverified from "../../components/unverified";
import NotificationBanner from "../../components/notification-banner";

import { getInitialData } from "../../utils/";

const hours = [],
  minutes = [];

for (let i = 0; i < 24; i++) {
  hours.push(i < 10 ? "0" + i : "" + i);
}
for (let i = 0; i < 60; i++) {
  minutes.push(i < 10 ? "0" + i : "" + i);
}

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: "",
        error: "",
      },
      runDaily: false,
      runHour: "00",
      runMinutes: "00",
      submitSuccess: false,
      submitFail: false,
      submitErrorMessage: `Failed to submit form: please double check your info and try again.`,
      unpublish: false,
      verified: false,
      modalActive: false,
      supportMessage: "",
      supportSubmitted: false,
      supportError: "",
      appMessage: "",
      loading: true,
      isSuccess: false,
    };
  }

  componentDidMount() {
    getInitialData(
      "product-checkup-app",
      "https://productcheckup.marketagility.com"
    ).then((data) => {
      if (data.isSuccess) {
        const appSettings = data.appSettings;
        this.setState({
          ...this.state,
          loading: false,
          isSuccess: true,
          email: { ...this.state.email, value: appSettings.email },
          runDaily: appSettings.rundaily,
          runHour: appSettings.runhour,
          runMinutes: appSettings.runminutes,
          shop: appSettings.shop,
          verified: true,
          tagMissingImages: appSettings.tagOnMissingImage,
          tagMissingImagesText: appSettings.tagOnMissingImage
            ? "BE TAGGED"
            : "NOT BE TAGGED",
          delistMissingImages: appSettings.unpublishOnMissingImage,
          delistMissingImagesText: appSettings.unpublishOnMissingImage
            ? "WILL NOT BE AVAILABLE"
            : "MAY BE AVAILABLE",
          tagMissingVendor: appSettings.tagOnMissingVendor,
          tagMissingVendorText: appSettings.tagOnMissingVendor
            ? "BE TAGGED"
            : "NOT BE TAGGED",
          delistMissingVendor: appSettings.unpublishOnMissingVendor,
          delistMissingVendorText: appSettings.unpublishOnMissingVendor
            ? "WILL NOT BE AVAILABLE"
            : "MAY BE AVAILABLE",
          tagMissingDescription: appSettings.tagOnMissingDescription,
          tagMissingDescriptionText: appSettings.tagOnMissingDescription
            ? "BE TAGGED"
            : "NOT BE TAGGED",
          delistMissingDescription: appSettings.unpublishOnMissingDescription,
          delistMissingDescriptionText: appSettings.unpublishOnMissingDescription
            ? "WILL NOT BE AVAILABLE"
            : "MAY BE AVAILABLE",
          reportDuplicateDescriptions: appSettings.reportDuplicateDescriptions,
          tagMissingProductType: appSettings.tagOnMissingProductType,
          tagMissingProductTypeText: appSettings.tagOnMissingProductType
            ? "BE TAGGED"
            : "NOT BE TAGGED",
          delistMissingProductType: appSettings.unpublishOnMissingProductType,
          delistMissingProductTypeText: appSettings.unpublishOnMissingProductType
            ? "WILL NOT BE AVAILABLE"
            : "MAY BE AVAILABLE",
          reportDuplicateSkus: appSettings.reportDuplicateSkus,
          reportDuplicateBarcodes: appSettings.reportDuplicateBarcodes,
          reportZeroPrices: appSettings.reportZeroPrices
        });
      } else {
        // handle verify errors
        this.setState({
          ...this.state,
          loading: false,
          isSuccess: false,
          verified: false,
          errorCode: data.errorCode,
          redirectUrl: data.redirectUrl,
        });
      }
    });
  }

  handleChange = (field) => {
    return (newValue) => {
      this.setState({
        ...this.state,
        [field]: {
          value: newValue,
        },
      });
    };
  };

  handleRunDailyChange = (newChecked) => {
    this.setState({ ...this.state, runDaily: newChecked });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    let errors = 0;
    const { email } = this.state;
    this.setState({
      ...this.state,
      submitFail: false,
      submitSuccess: false,
    });
    if (!email.value.length) {
      errors++;
      this.setState({
        ...this.state,
        email: { ...email, error: "Email address is required" },
        submitFail: true,
        submitSuccess: false,
      });
    }
    if (errors < 1) {
      this.submitData();
    }
  };

  submitData = () => {
    const {
      email,
      tagMissingImages,
      delistMissingImages,
      tagMissingVendor,
      delistMissingVendor,
      tagMissingDescription,
      delistMissingDescription,
      reportDuplicateDescriptions,
      tagMissingProductType,
      delistMissingProductType,
      reportDuplicateSkus,
      reportDuplicateBarcodes,
      reportZeroPrices,
      runDaily,
      runHour,
      runMinutes,
      shop,
    } = this.state;

    const url =
      "https://productcheckup.marketagility.com/api/run?shop=" +
      shop +
      "&email=" +
      email.value +
      "&tagOnMissingImage=" +
      tagMissingImages +
      "&unpublishOnMissingImage=" +
      delistMissingImages +
      "&tagOnMissingVendor=" +
      tagMissingVendor +
      "&unpublishOnMissingVendor=" +
      delistMissingVendor +
      "&tagOnMissingDescription=" +
      tagMissingDescription +
      "&unpublishOnMissingDescription=" +
      delistMissingDescription +
      "&reportDuplicateDescriptions=" +
      reportDuplicateDescriptions +
      "&tagOnMissingProductType=" +
      tagMissingProductType +
      "&unpublishOnMissingProductType=" +
      delistMissingProductType +
      "&reportDuplicateSkus=" +
      reportDuplicateSkus +
      "&reportDuplicateBarcodes=" +
      reportDuplicateBarcodes +
      "&reportZeroPrices=" +
      reportZeroPrices +
      "&rundaily=" +
      runDaily +
      "&runhour=" +
      runHour +
      "&runminutes=" +
      runMinutes;

    const postSuccess = (response) => {
      if (response.ok) {
        this.setState({
          submitSuccess: true,
          submitFail: false,
        });
      } else {
        this.setState({
          submitSuccess: false,
          submitFail: true,
        });
      }
      window.scrollTo({ top: 0, left: 0 });
    };

    const postFail = (error) => {
      console.error("post failed: ", error);
      this.setState({
        submitSuccess: false,
        submitFail: true,
      });
    };

    const postData = () => {
      return fetch(url, {
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    };

    postData().then(postSuccess).catch(postFail);
  };

  handleToggleSetting = function (setting, isChecked) {
    const action = setting.indexOf("tag") === 0 ? "tag" : "delist";
    const settingText = setting + "Text";
    let settingLabelStr;
    if (action === "tag") {
      settingLabelStr = isChecked ? "BE TAGGED" : "NOT BE TAGGED";
    } else {
      settingLabelStr = isChecked
        ? "WILL NOT BE AVAILABLE"
        : "MAY BE AVAILABLE";
    }
    this.setState({
      ...this.state,
      [setting]: isChecked,
      [settingText]: settingLabelStr,
    });
  };

  handleChangeTime = (newValue, field) => {
    this.setState({
      ...this.state,
      [field]: newValue,
    });
  };

  toggleModal = () => {
    this.setState({
      ...this.state,
      modalActive: !this.state.modalActive,
      supportMessage: this.state.modalActive ? "" : this.state.supportMessage,
    });
  };

  handleDismissBanner = () => {
    this.setState({
      ...this.state,
      submitSuccess: false,
      submitFail: false,
      submitErrorMessage: `Failed to submit form: please double check your info and try again.`,
    });
  };

  onSupportSubmit = () => {
    const { supportMessage, shop } = this.state;
    const url =
      "https://productcheckup.marketagility.com/api/support?shop=" +
      shop +
      "&message=" +
      supportMessage;
    const postSuccess = (response) => {
      if (response.ok) {
        this.setState({
          ...this.state,
          supportSubmitted: true,
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            supportSubmitted: false,
            supportMessage: "",
            modalActive: false,
            supportError: "",
          });
        }, 1500);
      }
    };
    const postFail = (error) => {
      console.error(error);
      this.setState({
        ...this.state,
        supportError: error,
      });
    };
    const postSupport = () => {
      return fetch(url, {
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    };

    if (supportMessage.trim().length < 1) {
      this.setState({
        ...this.state,
        supportError: "Please enter a message",
      });
    } else {
      this.setState({
        ...this.state,
        supportError: "",
      });
      postSupport().then(postSuccess).catch(postFail);
    }
  };

  render() {
    const {
      email,
      tagMissingImagesText,
      delistMissingImagesText,
      tagMissingImages,
      delistMissingImages,
      tagMissingVendorText,
      delistMissingVendorText,
      tagMissingVendor,
      delistMissingVendor,
      tagMissingDescriptionText,
      delistMissingDescriptionText,
      tagMissingDescription,
      delistMissingDescription,
      reportDuplicateDescriptions,
      tagMissingProductTypeText,
      delistMissingProductTypeText,
      tagMissingProductType,
      delistMissingProductType,
      reportDuplicateSkus,
      reportDuplicateBarcodes,
      reportZeroPrices,
      submitSuccess,
      submitFail,
      submitErrorMessage,
      verified,
      runDaily,
      runHour,
      runMinutes,
      modalActive,
      supportMessage,
      supportSubmitted,
      supportError,
      loading,
      errorCode,
      redirectUrl,
      isSuccess,
    } = this.state;
    const strMissingImage = `Tag products that don't have an image with "_missing-image"`,
      strMissingVendor = `Tag products that don't have a vendor with "_missing-vendor"`,
      strMissingDescription = `Tag products that don't have a description with "_missing-description"`,
      strMissingProductType = `Tag products that don't have a product type with "_missing-producttype"`,
      strDuplicateSkus = `Report any duplicate SKUs`,
      strDuplicateBarcodes = `Report any duplicate barcodes`,
      strZeroPrices = `Report any products with $0.00 price`;
    let notificationBanner = "";
    if (submitSuccess) {
      notificationBanner = (
        <NotificationBanner
          handleOnDismiss={this.handleDismissBanner}
          bannerMessage="You will receive an email when the updates are complete!"
          bannerType="success"
        />
      );
    }
    if (submitFail && submitErrorMessage) {
      notificationBanner = (
        <NotificationBanner
          handleOnDismiss={this.handleDismissBanner}
          bannerMessage={submitErrorMessage}
          bannerType="error"
        />
      );
    }
    let noDataComponent = (
      <SkeletonPage primaryAction={false} title={false}>
        <Layout.AnnotatedSection
          title={<SkeletonDisplayText />}
          description={<SkeletonBodyText />}
        >
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.AnnotatedSection>
      </SkeletonPage>
    );
    if (!loading && !isSuccess) {
      noDataComponent = (
        <Unverified
          errorCode={errorCode}
          url={redirectUrl}
          appName="Product Checkup"
        />
      );
    }
    return verified ? (
      <Page>
        <Layout>
          <ContactModal
            toggleModal={this.toggleModal}
            supportSubmit={this.onSupportSubmit}
            modalActive={modalActive}
            supportSubmitted={supportSubmitted}
            updateSupportMessage={(newVal) => {
              this.setState({
                ...this.state,
                supportMessage: newVal,
              });
            }}
            supportMessage={supportMessage}
            supportError={supportError}
          />
          {notificationBanner}
          <Layout.AnnotatedSection
            title="Configure Product Checkup"
            description="Choose what to do if a product detail is missing."
          >
            <Card sectioned title="Image Checkup">
              <FormLayout>
                <p>
                  Products with no images will{" "}
                  <strong>{tagMissingImagesText}</strong> and{" "}
                  <strong>{delistMissingImagesText}</strong> in your online
                  store sales channel.
                </p>
                <Checkbox
                  label={strMissingImage}
                  checked={tagMissingImages}
                  onChange={(e) => {
                    this.handleToggleSetting("tagMissingImages", e);
                  }}
                />
                <Checkbox
                  label="Make products that don't have an image unavailable in your online store sales channel"
                  checked={delistMissingImages}
                  onChange={(e) => {
                    this.handleToggleSetting("delistMissingImages", e);
                  }}
                />
              </FormLayout>
            </Card>
            <Card sectioned title="Description Checkup">
              <FormLayout>
                <p>
                  Products with a missing description will{" "}
                  <strong>{tagMissingDescriptionText}</strong> and{" "}
                  <strong>{delistMissingDescriptionText}</strong> in your online
                  store sales channel.
                </p>
                <Checkbox
                  label={strMissingDescription}
                  checked={tagMissingDescription}
                  onChange={(e) => {
                    this.handleToggleSetting("tagMissingDescription", e);
                  }}
                />
                <Checkbox
                  label="Make products that don't have a description unavailable in your online store sales channel"
                  checked={delistMissingDescription}
                  onChange={(e) => {
                    this.handleToggleSetting("delistMissingDescription", e);
                  }}
                />
                <Checkbox
                  label="Report duplicate descriptions"
                  checked={reportDuplicateDescriptions}
                  onChange={(e) => {
                    this.handleToggleSetting("reportDuplicateDescriptions", e);
                  }}
                />
              </FormLayout>
            </Card>
            <Card sectioned title="Product Type Checkup">
              <FormLayout>
                <p>
                  Products with a missing product type will{" "}
                  <strong>{tagMissingProductTypeText}</strong> and{" "}
                  <strong>{delistMissingProductTypeText}</strong> in your online
                  store sales channel.
                </p>
                <Checkbox
                  label={strMissingProductType}
                  checked={tagMissingProductType}
                  onChange={(e) => {
                    this.handleToggleSetting("tagMissingProductType", e);
                  }}
                />
                <Checkbox
                  label="Make products that don't have a product type unavailable in your online store sales channel"
                  checked={delistMissingProductType}
                  onChange={(e) => {
                    this.handleToggleSetting("delistMissingProductType", e);
                  }}
                />
              </FormLayout>
            </Card>
            <Card sectioned title="Vendor Checkup">
              <FormLayout>
                <p>
                  Products with a missing vendor will{" "}
                  <strong>{tagMissingVendorText}</strong> and{" "}
                  <strong>{delistMissingVendorText}</strong> in your online
                  store sales channel.
                </p>
                <Checkbox
                  label={strMissingVendor}
                  checked={tagMissingVendor}
                  onChange={(e) => {
                    this.handleToggleSetting("tagMissingVendor", e);
                  }}
                />
                <Checkbox
                  label="Make products that don't have a vendor unavailable in your online store sales channel"
                  checked={delistMissingVendor}
                  onChange={(e) => {
                    this.handleToggleSetting("delistMissingVendor", e);
                  }}
                />
              </FormLayout>
            </Card>
            <Card sectioned title="SKU Checkup">
              <FormLayout>
                <Checkbox
                  label={strDuplicateSkus}
                  checked={reportDuplicateSkus}
                  onChange={(e) => {
                    this.handleToggleSetting("reportDuplicateSkus", e);
                  }}
                />
                <Checkbox
                  label={strDuplicateBarcodes}
                  checked={reportDuplicateBarcodes}
                  onChange={(e) => {
                    this.handleToggleSetting("reportDuplicateBarcodes", e);
                  }}
                />
                <Checkbox
                  label={strZeroPrices}
                  checked={reportZeroPrices}
                  onChange={(e) => {
                    this.handleToggleSetting("reportZeroPrices", e);
                  }}
                />
              </FormLayout>
            </Card>
            <Card sectioned title="Notification Email">
              <FormLayout>
                <TextField
                  error={email.error}
                  id="TextEmailAddress"
                  label="What email should we notify when the process is complete?"
                  onChange={this.handleChange("email")}
                  placeholder="username@email.com"
                  type="email"
                  value={email.value}
                />
              </FormLayout>
            </Card>
            <Card sectioned title="Run Daily">
              <FormLayout>
                <Checkbox
                  label="Run Product Checkup once a day"
                  checked={runDaily}
                  onChange={this.handleRunDailyChange}
                />
                {runDaily ? (
                  <>
                    <TextContainer>
                      At what time? (Coordinated Universal Time - UTC)
                    </TextContainer>
                    <FormLayout.Group>
                      <Select
                        label="Hour"
                        options={hours}
                        onChange={(val) => {
                          this.handleChangeTime(val, "runHour");
                        }}
                        value={runHour}
                      />
                      <Select
                        label="Minute"
                        options={minutes}
                        onChange={(val) => {
                          this.handleChangeTime(val, "runMinutes");
                        }}
                        value={runMinutes}
                      />
                    </FormLayout.Group>
                  </>
                ) : (
                  ""
                )}
              </FormLayout>
            </Card>
            <Card sectioned>
              <FormLayout>
                <p>
                  Click the button below to save your settings and run Product
                  Checkup. Based on your settings, the App will:
                </p>
                <ul>
                  <li>add any tags needed</li>
                  <li>remove any tags that no longer apply</li>
                  <li>hide incomplete products</li>
                  <li>send a summary email once the process is completed</li>
                </ul>
                <Button
                  disabled={!verified}
                  primary
                  onClick={this.handleSubmit}
                >
                  Save Changes and Run Checkup
                </Button>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.Section>
            <FooterHelp>
              Need help?{" "}
              <Button plain onClick={this.toggleModal}>
                Contact us
              </Button>
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
    ) : (
      noDataComponent
    );
  }
}
